var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("headbox", {
        attrs: {
          title: "常见问题",
          bgColor: "#fff",
          isborder: "true",
          isbackPath: true,
          path: "person"
        }
      }),
      _c(
        "van-collapse",
        {
          model: {
            value: _vm.activeNames,
            callback: function($$v) {
              _vm.activeNames = $$v
            },
            expression: "activeNames"
          }
        },
        _vm._l(_vm.problemlist, function(items) {
          return _c(
            "div",
            { key: items.id },
            [
              _c(
                "van-collapse-item",
                { attrs: { title: items.title, name: items.id } },
                [
                  _c("div", { staticClass: "maxcenter" }, [
                    _vm._v(_vm._s(items.content))
                  ])
                ]
              )
            ],
            1
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }