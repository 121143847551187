<template>
  <!-- 常见问题 -->
  <div>
    <headbox title="常见问题" bgColor="#fff" isborder="true" :isbackPath="true" path="person"></headbox>
    <van-collapse v-model="activeNames">
      <div v-for="items in problemlist" :key="items.id">
        <van-collapse-item :title="items.title" :name="items.id"
          ><div class="maxcenter">{{ items.content }}</div></van-collapse-item
        >
      </div>
    </van-collapse>
  </div>
</template>
<script>
import headbox from "@/components/head.vue";
import * as commonpro from "@/utils/api";
export default {
  data() {
    return {
      activeNames: ["1"],
      problemlist: []
    };
  },
  mounted() {
    commonpro.queryFrequentlyQuestionList().then(res => {
      this.problemlist = res.data;
      console.log(this.problemlist);
    });
  },
  components: {
    headbox
  }
};
</script>
<style lang="scss" scoped="true">
::v-deep .van-collapse-item__title:after {
  content: none !important;
}
::v-deep .van-collapse-item__content {
  padding: 0;
}
::v-deep .van-collapse {
  margin: 0 30px;
}
::v-deep .van-icon {
  font-size: 36px;
  margin-top: 35px;
  transform: translate(-50%);
}
::v-deep .van-cell {
  border-bottom: 1px solid #cccccc;
  padding: 0;
}
::v-deep .van-cell__title {
  color: #292929;
  font-size: 32px;
  // margin: 0 30px;
  padding: 35px 0;
}
.maxcenter {
  line-height: 48px;
  // margin: 0 30px;
  padding: 30px 0;
  font-size: 26px;
  border-bottom: 1px solid #cccccc;
}
.silderarrow {
  transform: rotate(180deg);
}
</style>
